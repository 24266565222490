import { ISkills } from './interfaces';
import { skills } from './skills';

export const decorationsLevel1List: ISkills[] = [
  { skill: skills.ReloadSpeed, addedNumber: 1 },
  { skill: skills.BlastResistance, addedNumber: 1 },
  { skill: skills.PoisonResistance, addedNumber: 1 },
  { skill: skills.ParalysisResistance, addedNumber: 1 },
  { skill: skills.FireAttack, addedNumber: 1 },
  { skill: skills.ThunderAttack, addedNumber: 1 },
  { skill: skills.Bombardier, addedNumber: 1 },
  { skill: skills.Botanist, addedNumber: 1 },
  { skill: skills.FlinchFree, addedNumber: 1 },
  { skill: skills.CarvingPro, addedNumber: 1 },
  { skill: skills.DefenseBoost, addedNumber: 1 },
  { skill: skills.LeapOfFaith, addedNumber: 1 },
  { skill: skills.Diversion, addedNumber: 1 },
  { skill: skills.DragonAttack, addedNumber: 1 },
  { skill: skills.DragonResistance, addedNumber: 1 },
  { skill: skills.StaminaThief, addedNumber: 1 },
  { skill: skills.FireResistance, addedNumber: 1 },
  { skill: skills.IceAttack, addedNumber: 1 },
  { skill: skills.Geologist, addedNumber: 1 },
  { skill: skills.SpeedSharpening, addedNumber: 1 },
  { skill: skills.HungerResistance, addedNumber: 1 },
  { skill: skills.IceResistance, addedNumber: 1 },
  { skill: skills.MuckResistance, addedNumber: 1 },
  { skill: skills.SleepResistance, addedNumber: 1 },
  { skill: skills.RecoilDown, addedNumber: 1 },
  { skill: skills.RecoverySpeed, addedNumber: 1 },
  { skill: skills.FreeMeal, addedNumber: 1 },
  { skill: skills.AffinitySliding, addedNumber: 1 },
  { skill: skills.Steadiness, addedNumber: 1 },
  { skill: skills.HornMaestro, addedNumber: 1 },
  { skill: skills.StunResistance, addedNumber: 1 },
  { skill: skills.WaterAttack, addedNumber: 1 },
  { skill: skills.ThunderResistance, addedNumber: 1 },
  { skill: skills.PoisonAttack, addedNumber: 1 },
  { skill: skills.WaterResistance, addedNumber: 1 },
  { skill: skills.Windproof, addedNumber: 1 },
];

export const decorationsLevel2List: ISkills[] = [
  ...decorationsLevel1List,
  { skill: skills.Artillery, addedNumber: 1 },
  { skill: skills.AttackBoost, addedNumber: 1 },
  { skill: skills.Bludgeoner, addedNumber: 1 },
  { skill: skills.BubblyDance, addedNumber: 1 },
  { skill: skills.Focus, addedNumber: 1 },
  { skill: skills.Counterstrike, addedNumber: 1 },
  { skill: skills.CriticalElement, addedNumber: 1 },
  { skill: skills.Partbreaker, addedNumber: 1 },
  { skill: skills.ItemProlonger, addedNumber: 1 },
  { skill: skills.PowerProlonger, addedNumber: 1 },
  { skill: skills.EvadeWindow, addedNumber: 1 },
  { skill: skills.CriticalEye, addedNumber: 1 },
  { skill: skills.TremorResistance, addedNumber: 1 },
  { skill: skills.Fortify, addedNumber: 1 },
  { skill: skills.WideRange, addedNumber: 1 },
  { skill: skills.PunishingDraw, addedNumber: 1 },
  { skill: skills.SpeedEating, addedNumber: 1 },
  { skill: skills.Guard, addedNumber: 1 },
  { skill: skills.EvadeExtender, addedNumber: 1 },
  { skill: skills.Slugger, addedNumber: 1 },
  { skill: skills.LoadShells, addedNumber: 1 },
  { skill: skills.RecoveryUp, addedNumber: 1 },
  { skill: skills.MaximumMight, addedNumber: 1 },
  { skill: skills.MindsEye, addedNumber: 1 },
  { skill: skills.Constitution, addedNumber: 1 },
  { skill: skills.Heroics, addedNumber: 1 },
  { skill: skills.AimBooster, addedNumber: 1 },
  { skill: skills.DivineBlessing, addedNumber: 1 },
  { skill: skills.RapidMorph, addedNumber: 1 },
  { skill: skills.StaminaSurge, addedNumber: 1 },
  { skill: skills.BlightResistance, addedNumber: 1 },
  { skill: skills.MasterMounter, addedNumber: 1 },
  { skill: skills.QuickSheath, addedNumber: 1 },
  { skill: skills.GuardUp, addedNumber: 1 },
  { skill: skills.MarathonRunner, addedNumber: 1 },
  { skill: skills.SpecialAmmoBoost, addedNumber: 1 },
  { skill: skills.WallRunner, addedNumber: 1 },
  { skill: skills.WirebugWhisperer, addedNumber: 1 },
  { skill: skills.ProtectivePolish, addedNumber: 1 },
  { skill: skills.BlastAttack, addedNumber: 1 },
  { skill: skills.SleepAttack, addedNumber: 1 },
  { skill: skills.ParalysisAttack, addedNumber: 1 },
  { skill: skills.WeaknessExploit, addedNumber: 1 },
  { skill: skills.Ballistics, addedNumber: 1 },
  { skill: skills.LatentPower, addedNumber: 1 },
  { skill: skills.Resuscitate, addedNumber: 1 },
  { skill: skills.Resentment, addedNumber: 1 },
  { skill: skills.PeakPerformance, addedNumber: 1 },
  { skill: skills.Agitator, addedNumber: 1 },
  { skill: skills.MastersTouch, addedNumber: 1 },
  { skill: skills.CriticalBoost, addedNumber: 1 },
];

export const decorationsLevel3List: ISkills[] = [
  ...decorationsLevel2List,
  { skill: skills.GoodLuck, addedNumber: 1 },
  { skill: skills.HellfireCloak, addedNumber: 1 },
  { skill: skills.JumpMaster, addedNumber: 1 },
  { skill: skills.RapidFireUp, addedNumber: 1 },
  { skill: skills.SpareShot, addedNumber: 1 },
  { skill: skills.RazorSharp, addedNumber: 1 },
  { skill: skills.SpreadUp, addedNumber: 1 },
  { skill: skills.PierceUp, addedNumber: 1 },
  { skill: skills.NormalRapidUp, addedNumber: 1 },
  { skill: skills.AmmoUp, addedNumber: 1 },
  { skill: skills.CriticalDraw, addedNumber: 1 },
  { skill: skills.Earplugs, addedNumber: 1 },
  { skill: skills.Mushroomancer, addedNumber: 1 },
  { skill: skills.OffensiveGuard, addedNumber: 1 },
  { skill: skills.Handicraft, addedNumber: 1 },
];
