

































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { armourType, IArmour, ISkills } from '@/modules/interfaces';
import Decoration from '@/components/Decoration.vue';

@Component({ components: { Decoration } })
export default class Armour extends Vue {
  @Prop() private armourOptions!: IArmour[];
  @Prop() private armourType!: string;

  private armour: IArmour = {
    name: '',
    defense: 0,
    decorations: [],
    skills: [],
  };
  private decorationSlot1: ISkills = {
    skill: { name: '', maxNumber: 0 },
    addedNumber: 0,
  };
  private decorationSlot2: ISkills = {
    skill: { name: '', maxNumber: 0 },
    addedNumber: 0,
  };
  private decorationSlot3: ISkills = {
    skill: { name: '', maxNumber: 0 },
    addedNumber: 0,
  };

  private get decorationsSlots(): ISkills[] {
    const result: ISkills[] = [];
    if (this.decorationSlot1.skill.name !== '') result.push(this.decorationSlot1);
    if (this.decorationSlot2.skill.name !== '') result.push(this.decorationSlot2);
    if (this.decorationSlot3.skill.name !== '') result.push(this.decorationSlot3);
    return result;
  }

  private searchableArmourOptions = this.armourOptions;

  private customLabel(armour: IArmour): string {
    let skills = '';
    armour.skills.forEach((skill) => {
      skills += ' ' + skill.skill.name;
    });
    return armour.name + skills;
  }

  private searchForSkillOrName(query: string) {
    if (query.length === 0) {
      this.searchableArmourOptions = this.armourOptions;
      return;
    }
    const lowerCaseQuery = query.toLowerCase();
    const result: IArmour[] = [];
    this.armourOptions.forEach((element) => {
      if (element.name.toLowerCase().includes(lowerCaseQuery)) {
        result.push(element);
      } else {
        element.skills.forEach((skill) => {
          if (skill.skill.name.toLowerCase().includes(lowerCaseQuery)) {
            result.push(element);
          }
        });
      }
    });
    this.searchableArmourOptions = result;
  }

  $refs!: {
    form: Decoration[];
  };

  @Watch('armour')
  armourChanged(val: IArmour, oldVal: IArmour) {
    for (let index = 0; index < oldVal.decorations.length; index++) {
      this.$refs.form[index].clearSlot();
    }
    switch (this.armourType) {
      case armourType.Helmet:
        this.$emit('helmetChanged', val);
        break;
      case armourType.Body:
        this.$emit('bodyChanged', val);
        break;
      case armourType.Gloves:
        this.$emit('glovesChanged', val);
        break;
      case armourType.Belt:
        this.$emit('beltChanged', val);
        break;
      case armourType.Boots:
        this.$emit('bootsChanged', val);
        break;
      default:
        break;
    }
  }

  @Watch('decorationsSlots')
  decorationsSlotsChanged(val: ISkills[]) {
    switch (this.armourType) {
      case armourType.Helmet:
        this.$emit('helmetSlotsChanged', val);
        break;
      case armourType.Body:
        this.$emit('bodySlotsChanged', val);
        break;
      case armourType.Gloves:
        this.$emit('glovesSlotsChanged', val);
        break;
      case armourType.Belt:
        this.$emit('beltSlotsChanged', val);
        break;
      case armourType.Boots:
        this.$emit('bootsSlotsChanged', val);
        break;
      default:
        break;
    }
  }

  private slot1Changed(val: ISkills) {
    this.decorationSlot1 = val;
  }
  private slot2Changed(val: ISkills) {
    this.decorationSlot2 = val;
  }
  private slot3Changed(val: ISkills) {
    this.decorationSlot3 = val;
  }
}
