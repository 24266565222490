








import { ISkills } from '@/modules/interfaces';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Status extends Vue {
  @Prop() private skills!: ISkills[];
}
