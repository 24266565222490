import { ISkill } from './interfaces';

export const skills = {
  AffinitySliding: { name: 'Affinity Sliding', maxNumber: 1 },
  Agitator: { name: 'Agitator', maxNumber: 5 },
  AimBooster: { name: 'Aim Booster', maxNumber: 3 },
  AmmoUp: { name: 'Ammo Up', maxNumber: 3 },
  Artillery: { name: 'Artillery', maxNumber: 3 },
  AttackBoost: { name: 'Attack Boost', maxNumber: 7 },
  Ballistics: { name: 'Ballistics', maxNumber: 3 },
  BlastAttack: { name: 'Blast Attack', maxNumber: 3 },
  BlastResistance: { name: 'Blast Resistance', maxNumber: 3 },
  BlightResistance: { name: 'Blight Resistance', maxNumber: 3 },
  Bludgeoner: { name: 'Bludgeoner', maxNumber: 3 },
  Bombardier: { name: 'Bombardier', maxNumber: 3 },
  Botanist: { name: 'Botanist', maxNumber: 5 },
  BowChargePlus: { name: 'Bow Charge Plus', maxNumber: 1 },
  BubblyDance: { name: 'Bubbly Dance', maxNumber: 3 },
  CaptureMaster: { name: 'Capture Master', maxNumber: 1 },
  CarvingPro: { name: 'Carving Pro', maxNumber: 1 },
  ChameleosBlessing: { name: 'Chameleos Blessing', maxNumber: 4 },
  Constitution: { name: 'Constitution', maxNumber: 5 },
  Counterstrike: { name: 'Counterstrike', maxNumber: 3 },
  CriticalBoost: { name: 'Critical Boost', maxNumber: 3 },
  CriticalDraw: { name: 'Critical Draw', maxNumber: 3 },
  CriticalElement: { name: 'Critical Element', maxNumber: 3 },
  CriticalEye: { name: 'Critical Eye', maxNumber: 7 },
  DefenseBoost: { name: 'Defense Boost', maxNumber: 7 },
  Diversion: { name: 'Diversion', maxNumber: 1 },
  DivineBlessing: { name: 'Divine Blessing', maxNumber: 3 },
  DragonAttack: { name: 'Dragon Attack', maxNumber: 5 },
  DragonResistance: { name: '	Dragon Resistance', maxNumber: 3 },
  Earplugs: { name: 'Earplugs', maxNumber: 5 },
  EvadeExtender: { name: 'Evade Extender', maxNumber: 3 },
  EvadeWindow: { name: 'Evade Window', maxNumber: 5 },
  FireAttack: { name: 'Fire Attack', maxNumber: 5 },
  FireResistance: { name: 'Fire Resistance', maxNumber: 3 },
  FlinchFree: { name: 'Flinch Free', maxNumber: 3 },
  Focus: { name: 'Focus', maxNumber: 3 },
  Fortify: { name: 'Fortify', maxNumber: 1 },
  FreeMeal: { name: 'Free Meal', maxNumber: 3 },
  Geologist: { name: 'Geologist', maxNumber: 3 },
  GoodLuck: { name: 'Good Luck', maxNumber: 3 },
  Guard: { name: 'Guard', maxNumber: 5 },
  GuardUp: { name: 'Guard Up', maxNumber: 3 },
  Handicraft: { name: 'Handicraft', maxNumber: 3 },
  HellfireCloak: { name: 'Hellfire Cloak', maxNumber: 4 },
  Heroics: { name: 'Heroics', maxNumber: 5 },
  HornMaestro: { name: 'Horn Maestro', maxNumber: 1 },
  HungerResistance: { name: 'Hunger Resistance', maxNumber: 3 },
  IceAttack: { name: 'Ice Attack', maxNumber: 5 },
  IceResistance: { name: 'Ice Resistance', maxNumber: 5 },
  ItemProlonger: { name: 'Item Prolonger', maxNumber: 3 },
  JumpMaster: { name: 'Jump Master', maxNumber: 1 },
  KushalaBlessing: { name: 'Kushala Blessing', maxNumber: 4 },
  LatentPower: { name: 'Latent Power', maxNumber: 5 },
  LeapOfFaith: { name: 'Leap of Faith', maxNumber: 1 },
  LoadShells: { name: 'Load Shells', maxNumber: 2 },
  MarathonRunner: { name: 'Marathon Runner', maxNumber: 3 },
  MasterMounter: { name: 'Master Mounter', maxNumber: 1 },
  MastersTouch: { name: 'Masters Touch', maxNumber: 3 },
  MaximumMight: { name: 'Maximum Might', maxNumber: 3 },
  MindsEye: { name: 'Minds Eye', maxNumber: 3 },
  MuckResistance: { name: 'Muck Resistance', maxNumber: 2 },
  Mushroomancer: { name: 'Mushroomancer', maxNumber: 3 },
  NormalRapidUp: { name: 'Normal/Rapid Up', maxNumber: 1 },
  OffensiveGuard: { name: 'Offensive Guard', maxNumber: 3 },
  ParalysisAttack: { name: 'Paralysis Attack', maxNumber: 3 },
  ParalysisResistance: { name: 'Paralysis Resistance', maxNumber: 3 },
  Partbreaker: { name: 'Partbreaker', maxNumber: 3 },
  PeakPerformance: { name: 'Peak Performance', maxNumber: 3 },
  PierceUp: { name: 'Pierce Up', maxNumber: 3 },
  PoisonAttack: { name: 'Poison Attack', maxNumber: 3 },
  PoisonResistance: { name: 'Poison Resistance', maxNumber: 3 },
  PowerProlonger: { name: 'Power Prolonger', maxNumber: 3 },
  ProtectivePolish: { name: 'Protective Polish', maxNumber: 3 },
  PunishingDraw: { name: 'Punishing Draw', maxNumber: 3 },
  QuickSheath: { name: 'Quick Sheath', maxNumber: 3 },
  RapidFireUp: { name: 'Rapid Fire Up', maxNumber: 3 },
  RapidMorph: { name: 'Rapid Morph', maxNumber: 3 },
  RazorSharp: { name: 'Razor Sharp', maxNumber: 3 },
  RecoilDown: { name: 'Recoil Down', maxNumber: 3 },
  RecoverySpeed: { name: 'Recovery Speed', maxNumber: 3 },
  RecoveryUp: { name: 'Recovery Up', maxNumber: 3 },
  ReloadSpeed: { name: 'Reload Speed', maxNumber: 3 },
  Resentment: { name: 'Resentment', maxNumber: 5 },
  Resuscitate: { name: 'Resuscitate', maxNumber: 3 },
  SleepAttack: { name: 'Sleep Attack', maxNumber: 3 },
  SleepResistance: { name: 'Sleep Resistance', maxNumber: 3 },
  Slugger: { name: 'Slugger', maxNumber: 3 },
  SpareShot: { name: 'Spare Shot', maxNumber: 3 },
  SpecialAmmoBoost: { name: 'Special Ammo Boost', maxNumber: 2 },
  SpeedEating: { name: 'Speed Eating', maxNumber: 3 },
  SpeedSharpening: { name: 'Speed Sharpening', maxNumber: 3 },
  SpreadUp: { name: 'Spread Up', maxNumber: 3 },
  StaminaSurge: { name: 'Stamina Surge', maxNumber: 3 },
  StaminaThief: { name: 'Stamina Thief', maxNumber: 3 },
  Steadiness: { name: 'Steadiness', maxNumber: 2 },
  StunResistance: { name: 'Stun Resistance', maxNumber: 3 },
  TeostraBlessing: { name: 'Teostra Blessing', maxNumber: 4 },
  ThunderAlignment: { name: 'Thunder Alignment', maxNumber: 5 },
  ThunderAttack: { name: 'Thunder Attack', maxNumber: 5 },
  ThunderResistance: { name: '	Thunder Resistance', maxNumber: 3 },
  TremorResistance: { name: 'Tremor Resistance', maxNumber: 3 },
  WallRunner: { name: 'Wall Runner', maxNumber: 3 },
  WaterAttack: { name: 'Water Attack', maxNumber: 5 },
  WaterResistance: { name: 'Water Resistance', maxNumber: 3 },
  WeaknessExploit: { name: 'Weakness Exploit', maxNumber: 3 },
  WideRange: { name: 'Wide-Range', maxNumber: 5 },
  WindAlignment: { name: 'Wind Alignment', maxNumber: 5 },
  Windproof: { name: 'Windproof', maxNumber: 3 },
  WirebugWhisperer: { name: 'Wirebug Whisperer', maxNumber: 3 },
};
